import React from 'react';
import LCSS from '../Views/LCSS';
import SideBarButton from './SideBarButton';

import HomeIcon from '../assets/sidebar/sidebar_home.png';
import SystemsIcon from '../assets/sidebar/sidebar_systems.png';
import SysLogIcon from '../assets/sidebar/sidebar_syslog.png';
import SettingsIcon from '../assets/sidebar/sidebar_settings.png';
import ReportIcon from '../assets/sidebar/sidebar_reports.png';


export default class SideBar extends React.Component {

    render () {

        return (
            <div className="side-bar">
                <div className="side-bar-content">
                    <SideBarButton link="/">
                        <img src={HomeIcon} className="icon" />
                        <span className='icon-label'>Home</span>
                    </SideBarButton>
                    <SideBarButton link="/overview">
                        <img src={SystemsIcon} className="icon" />
                        <span className='icon-label'>UVC systems</span>
                    </SideBarButton>

                    {
                        LCSS.account.level < 127 &&
                        <SideBarButton link="/reports">
                        <img src={ReportIcon} className="icon" />
                        <span className='icon-label'>Reports</span>
                    </SideBarButton>
                    }


                    {
                        LCSS.account.level >= 127 &&
                        <div>
                            <SideBarButton link="/devices">
                                <img src={SysLogIcon} className="icon" />
                                <span className='icon-label'>UVC systems log</span>
                                
                            </SideBarButton>
                            <SideBarButton link="/settings">
                                <img src={SettingsIcon} className="icon" />
                                <span className='icon-label'>Settings</span>
                            </SideBarButton>
                            <SideBarButton link="/reports">
                                <img src={ReportIcon} className="icon" />
                                <span className='icon-label'>Reports</span>
                            </SideBarButton>
                        </div>
                    }
                </div>
                <h3 className="copyright">
                    &copy; SoftRain Blobs Oy 2021
                </h3>
            </div>
        );
    }
}