import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import SideBar from '../Components/SideBar';
import TopBar from '../Components/TopBar';
import WSController, { ClientWSCommands } from '../Components/WSController';
import Device, { DeviceType } from '../Misc/Device';
import DeviceDetailView from './DeviceDetailView';
import DeviceView from './DeviceView';
import LoginView from './LoginView';
import MapView from './MapView';
import ProfileView from './ProfileView';
import SettingsView from './SettingsView';
import { SystemsOverview } from './SystemsOverview';
import Report from './Report';
import ReportDetailViews from './ReportDetailView'
import ReportDeviceDetail from './ReportDeviceDetail';

const host = window.location.host.split(":")[0];
//const host = "lcss.softrain.fi";

/**
 * @enum {number}
 */
export const UserLevel = {
    USER:   1,
    ADMIN:  255
};

/**
 * @enum {number}
 */
export const LogType = {
    LOG:    0,
    WARN:   1,
    ERROR:  2
}

/**
 * @typedef {{code: number, name: string, description: string, type: LogType}} LogCode 
 */

/**
 * @brief Underlying view of all components
 */
export default class LCSS extends React.Component {

    /**
     * @brief Instance
     * @type {LCSS}
     */
    static instance = null;

    /**
     * @type WSController
     */
    static wscontroller = null;

    /**
     * @brief Currently selected device for DeviceDetailView
     * @type {string}
     */
    static selectedDeviceID = null;

    /**
     * @brief Settings
     */
    static settings = {
        wsAddress: "wss://" + host + ":4351",
        loginAddress: "https://" + host + ":4353"

    };

    /**
     * @brief Log codes
     * @type {LogCode[]}
     */
    static logCodes = [];
    
    /**
     * @brief Authenticated flag
     */
    static authenticated = false;

    /**
     * @brief Authentication token
     */
    static token = "";

    /**
     * @brief Account data
     * @type {{userID: number, username: string, level: number, displayName: string, organization: string}}
     */
    static account = {
        userID: 0,
        username: "",
        level: 0,
        displayName: "",
        organization: ""
    };

    /**
     * @type {{id: number, name: string}[]}
     */
    static organizations = [];

    /**
     * @type {{primary: string, images: {id: string, name: string, major: number, minor: number, file: string, device: ('LCDIO'|'LC')|undefined}[]}}
     */
    static firmwares = {
        primary: "",
        images: []
    };

    state = {
        authenticated: false,
        logCodes: [],
        // Force update
        rnd: 0 
    }

    constructor (props) {

        super(props);
        // Load settings
        let setting = localStorage.getItem("HMS-SETTINGS");
        let ok = false;
        if(setting !== null) {
            try {
                //LCSS.settings = { ...LCSS.settings, ...JSON.parse(setting) };
                ok = true;
            }
            catch {
                console.warn("Could not parse settings");
            }
        }
        if(!ok) {
            try {
                localStorage.setItem("HMS-SETTINGS", JSON.stringify(LCSS.settings));
            }
            catch (e) {
                console.warn("Could not initialize settings");
            }
        }


        this.wsOpen = this.wsOpen.bind(this);
        this.wsClose = this.wsClose.bind(this);
        this.wsMessage = this.wsMessage.bind(this);

        this.clearSettings = this.clearSettings.bind(this);

        this.onLogin = this.onLogin.bind(this);
        this.wsBegin = this.wsBegin.bind(this);

        this.logOut = this.logOut.bind(this);
        this.onDimensionsChange = this.onDimensionsChange.bind(this);

        LCSS.instance = this;
        console.log("HMS INIT");

    }

    /**
     * 
     * @param {Event} ev 
     */
    onDimensionsChange (ev) {
        this.forceUpdate();
    }

    componentDidMount () {
        let cookies = document.cookie;
        let cookieIndex = cookies.indexOf("login-token");
        if(cookieIndex >= 0) {
            let vindex = cookieIndex + "login-token=".length;
            let token = "";
            for(let i = vindex; i < cookies.length; i++) {
                if(cookies.charAt(i) === ';'.charAt(0)) {
                    break;
                }
                token += cookies.charAt(i);
            }
            LCSS.token = token;
            this.wsBegin();
        }
        else {
            // Token not found

        }
        window.addEventListener("resize", this.onDimensionsChange);
    }
    /**
     * @brief Clears the settings
     */
    clearSettings () {
        localStorage.clear();
    }

    componentWillUnmount () {
        // Should never unmount
        //HMS.wscontroller._onopen = null;
        //HMS.wscontroller._onclose = null;
        //HMS.wscontroller._onmessage = null;
        window.removeEventListener("resize", this.onDimensionsChange);
    }

    /**
     * @brief Fetches organizations
     * @param {Function{id: number, name: string}[]|undefined} cb
     */
    static fetchOrganizations (cb) {
        WSController.request({
            cmd: ClientWSCommands.GETORGS
        }, (data) => {
            if(data.status) {
               console.log(data)
                LCSS.organizations = cb.orgs;
                cb(data.orgs);
            }
            else {
                cb(undefined);
            }
        });
    }

    /**
     * @brief Fetches users
     * @param {{id: number, username: string, displayName: string, organizationId: number, organization: number, level: number}[]|undefined} cb
     */
    static fetchUsers (cb) {
        WSController.request({
            cmd: ClientWSCommands.GETUSERS
        }, (data) => {
            if(data.status) {
                cb(data.users);
            }
            else {
                cb(undefined);
            }
        });
    }

    onLogin () {
        console.log("Authentication successful, logging in");
        this.wsBegin();
    }

    logOut () {
        // TODO: SEND TOKEN REMOVAL MESSAGE IMPORTANT
        WSController.instance.ws.close();
        LCSS.token = "";
      document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
      window.location = "/";
    }

    static getLogCodes () {
        WSController.request({
            cmd: ClientWSCommands.GETLOGCODES
        }, (data) => {
            
            LCSS.logCodes = data.data;
            LCSS.instance.setState({logCodes: LCSS.logCodes});
            ReactTooltip.rebuild();
        });
    }

    static getFirmwares () {
        WSController.request({
            cmd: ClientWSCommands.GETFIRMWARES
        }, (data) => {
            if(data.status) {
                LCSS.firmwares = data.firmwares;
                console.log(LCSS.firmwares);
            }
            else {
                
            }
        })
    }

    wsBegin () {
        
        if(LCSS.wscontroller == null) {
            LCSS.wscontroller = new WSController({
                address: LCSS.settings.wsAddress,
                onOpen: this.wsOpen,
                onClose: this.wsClose,
                onMessage: this.wsMessage
            });
        }
        else {
            WSController.reconnect();
            LCSS.wscontroller._onopen = this.wsOpen;
            LCSS.wscontroller._onclose = this.wsClose;
            LCSS.wscontroller._onmessage = this.wsMessage;
        }
    }

    /**
     * 
     * @param {Event} ev 
     */
    wsOpen (ev) {
        console.log("Opened WS connection");
        WSController.send(JSON.stringify({
            cmd: ClientWSCommands.AUTH,
            token: LCSS.token
        }));
    }

    /**
     * 
     * @param {Event} ev 
     */
    wsClose (ev) {
        console.warn("WS connection was closed")
    }

    /**
     * 
     * @param {Event} ev 
     */
    wsMessage (ev) {
        let jsn = JSON.parse(ev.data);
        console.log(jsn, '123123')
        if(!LCSS.authenticated) {
            if(jsn.cmd === ClientWSCommands.AUTH) {
                if(jsn.status) {
                    LCSS.account = jsn.account;
                    LCSS.authenticated = true;
                    this.setState({authenticated: true});
                    console.log("WS Token login successfull");
                    LCSS.getLogCodes();
                    LCSS.getFirmwares();
                }
                else {
                    console.error("WS Token login failed");
                    return;
                }
            }
            else {
                return;
            }
        }
        switch(jsn.cmd) {
            case ClientWSCommands.GETDEVICES:
                // Receive all devices from the server
                for(let d of jsn.devices) {
              
                    let dev = Device.findDevice(d.deviceInfo.deviceID);
                    if(dev !== undefined) {
                        // Replace existing data
                        dev.active = d.active;
                        dev.updating = d.updating;
                        dev.firmware = d.firmware;
                        dev.wificnt = d.wificnt;
                        dev.chCutoff = d.chcutoff;
                        dev.majorVersion = d.swmaj;
                        dev.minorVersion = d.swmin;
                        dev.deviceInfo = { ...dev.deviceInfo, ...d.deviceInfo };
                        dev.deviceData = { ...dev.deviceData, ...d.deviceData };
                        // Loop through slaves
                        for(let s of d.slaves) {
                            let slv = dev.slaves.find((e) => e.deviceInfo.deviceid === s.deviceInfo.deviceID);
                            if(slv === undefined) {
                                // Create a new slave
                                slv = new Device(s.deviceInfo);
                            }
                            slv.deviceType = DeviceType.SLAVE;
                            slv.deviceInfo = { ...slv.deviceInfo, ...s.deviceInfo };
                            slv.deviceData = { ...slv.deviceData, ...s.deviceData };
                            slv.slaveData = { ...slv.slaveData, ...s.slaveData };
                            slv.channelDefinitions = s.channels;
                            slv.majorVersion = s.swmaj;
                            slv.minorVersion = s.swmin;
                            slv.master = dev;
                        }
                    }
                    else {
                        let ndev = new Device(d.deviceInfo);
                        ndev.active = d.active;
                        ndev.updating = d.updating;
                        ndev.firmware = d.firmware;
                        ndev.chCutoff = d.chcutoff;
                        ndev.majorVersion = d.swmaj;
                        ndev.minorVersion = d.swmin;
                        ndev.deviceData = { ...ndev.deviceData, ...d.deviceData };
                        ndev.masterData = { ...ndev.masterData, ...d.masterData };
                        ndev.deviceType = DeviceType.MASTER;
                        for(let s of d.slaves) {
                            let slv = new Device(s.deviceInfo);
                            slv.deviceData = { ...slv.deviceData, ...s.deviceData };
                            slv.slaveData = { ...slv.slaveData, ...s.slaveData };
                            slv.deviceType = DeviceType.SLAVE;
                            slv.master = ndev;
                            slv.channelDefinitions = s.channels;
                            slv.majorVersion = s.swmaj;
                            slv.minorVersion = s.swmin;
                            ndev.slaves.push(slv);
                        }
                    }
                }
                Device.update();
                // Fetch misc device data for all devices
                Device.getAllMiscData(Device.devices);
                
                break;
            case ClientWSCommands.NEWDEVICE:
                // Receive new device
                let d = new Device(jsn.device.deviceInfo);
                d.deviceType = DeviceType.MASTER;
                d.firmware = jsn.firmware;
                d.majorVersion = jsn.swmaj;
                d.minorVersion = jsn.swmin;
                
                for(let slv of jsn.device.slaves) {
                    let newslv = new Device(slv.deviceInfo);
                    newslv.master = d;
                    newslv.channelDefinitions = slv.channels;
                    newslv.deviceType = DeviceType.SLAVE;
                    d.slaves.push(newslv);
                }
                Device.update();
                break;
            case ClientWSCommands.SETDEVICENAME:
            {
                // Device name changed
                let d = Device.findDevice(jsn.device);
                if(d) {
                    d.deviceInfo.name = jsn.name;
                    jsn.slaves.forEach((v) => {
                        let s = d.slaves.find((e) => {
                            return e.deviceInfo.address === v.address;
                        });
                        if(s) {
                            s.deviceInfo.name = v.name;
                        }
                    });
                }
                break;
            }
            case ClientWSCommands.DATA:
                let mstr = Device.findDevice(jsn.deviceID);
                if(mstr) {
                    mstr.parseData(jsn.deviceData, jsn.masterData);
                    mstr.active = jsn.active;
                    mstr.updating = jsn.updating;
                }
                else {
                    console.warn("Data from unknown master!");
                }
                for(let d of jsn.slaves) {
                    let dev = Device.findDevice(d.deviceID);
                    if(dev) {
                        // Device found
                        dev.parseData(d.deviceData, d.slaveData);
                    }
                    else {
                        console.warn("Received data from unknown device!");
                    }
                }
                break;
            case ClientWSCommands.ACTIVE:
            {
                let dev = Device.findDevice(jsn.device);
                if(dev) {
                    dev.active = jsn.active;
                    dev.updating = false;
                    dev.firmware = jsn.firmware;

                    dev.updateListeners();
                }
                break;
            }
            case ClientWSCommands.DEVUPDATING:
            {
                let dev = Device.findDevice(jsn.device);
                if(dev) {
                    dev.updating = jsn.status;

                    dev.updateListeners();
                }
                break;
            }
            default:
            {
                break;
            }
        }

    }


    render () {
        if(!LCSS.authenticated) {
            return (<LoginView />);
        }

        return (
            <div className="content-wrapper">
                <Router>
                    <TopBar/>
                    <SideBar/>
                    <Switch>
                        <Route path="/profile">
                            <ProfileView />
                        </Route>
                        <Route path="/settings">
                            <SettingsView />
                        </Route>
                        <Route path="/overview">
                            <SystemsOverview />
                        </Route>
                        <Route path="/devices">
                            <DeviceView />
                        </Route>
                        <Route path="/device">
                            <DeviceDetailView />
                        </Route>
                        <Route path="/reports/:id" exact={true}>
                            <ReportDetailViews />
                        </Route>
                        <Route path="/reports/:reportId/:deviceId" exact={true}>
                            <ReportDeviceDetail />
                        </Route>
                        <Route path="/reports">
                            <Report />
                        </Route>
        
                        <Route path="/">
                            <MapView />
                        </Route>
                    </Switch>
                </Router>
                
            </div>
        );
    }

}