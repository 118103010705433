import React from "react";
import { Redirect } from "react-router/cjs/react-router.min";
import { FlexibleWidthXYPlot, VerticalBarSeries, LineSeries, XAxis, YAxis } from "react-vis";
import DeviceBox from "../Components/DeviceBox";
import Device, { DeviceType } from "../Misc/Device";
import { addZ, formatDateTime, formatTime, legendItem } from "../Misc/Helpers";
import LCSS from "./LCSS";
import Select from "react-select";

export default class DeviceView extends React.Component {
    /**
     * @brief Device update listener ID
     * @private
     */
    _devUpdateListener = -1;

    state = {
        /**
         * @type {Device[]}
         */
        devices: [],
        /**
         * @type {Device[]}
         */
        filteredDevices: [],
        /**
         * @brief For force update
         */
        rnd: 0,

        organizations: []
    }

    filters = {
        active: null,
        organizations: null,
        devices: null
    }

    constructor (props) {
        super(props);


        this.onDeviceUpdate = this.onDeviceUpdate.bind(this);
        
    }

    /**
     * @brief Device update callback
     * @param {Device} device 
     */
    onDeviceUpdate (device) {
        this.setState({devices: Device.devices});
        this.filterDevices();
    }

    filterDevices () {
        let devs = this.state.devices;

        // Filter by activity
        if(this.filters.active !== null) {
            devs = devs.filter(e => {
                return e.active === this.filters.active;    
            });
        }

        // Filter by devices
        if(this.filters.devices !== null && this.filters.devices.length > 0) {
            devs = devs.filter(e => {
                return this.filters.devices.find(y => {return e.deviceInfo.deviceid === y});
            });
        }
        
        // Filter by organization
        if(this.filters.organizations !== null && this.filters.organizations.length > 0) {
            devs = devs.filter(e => {
                let f = false;
                for(let org of e.organizations) {
                    if(this.filters.organizations.find(y => {return org.id === y})) {
                        f = true;
                        break;
                    }
                }
                return f;
            });
        }

        this.setState({filteredDevices: devs});
    }

    componentDidMount () {
        this._devUpdateListener = Device.addUpdateListener(this.onDeviceUpdate);
        this.state.devices = Device.devices;
        this.setState({devices: Device.devices});

        this.filterDevices();

        LCSS.fetchOrganizations((e) => {
            this.setState({organizations: e});
        })
    }

    componentWillUnmount () {
        if(!Device.removeUpdateListener(this._devUpdateListener)) {
            console.error("Failed to remove update listener!");
        }
    }

    render () {
        return (
            <div style={{width: '100%', boxSizing: 'border-box'}}>
                {/* DeviceView */}
                <div style={{overflowX: 'auto', overflowY: 'visible', margin: 20, marginBottom: 0, paddingBottom: 5, borderBottom: '1px solid rgba(0, 0, 0, 0.2)', flexDirection: 'row', display: 'flex'}}>
                    <div style={{flex: 1, flexDirection: 'row', display: 'flex'}}>
                        <span style={{flex: 1, textAlign: 'right', verticalAlign: 'middle', margin: 'auto', paddingRight: 10}}>Organization: </span>
                        <div style={{flex: 1, verticalAlign: 'middle'}}>
                            <Select
                                options={this.state.organizations.map(e => {return {label: e.name, value: e.id}})}
                                isMulti={true}
                                menuPosition='fixed'
                                onChange={(e) => {this.filters.organizations = e === null ? null : e.map(y => {return y.value;}); this.filterDevices()}} 
                            />
                        </div>
                    </div>
                    <div style={{flex: 1, flexDirection: 'row', display: 'flex'}}>
                        <span style={{flex: 1, textAlign: 'right', verticalAlign: 'middle', margin: 'auto', paddingRight: 10}}>Status: </span>
                        <div style={{flex: 1, verticalAlign: 'middle'}}>
                            <Select
                                options={[
                                    { label: "Offline", value: false },
                                    { label: "Online", value: true }

                                ]}
                                menuPosition='fixed'
                                isClearable={true}
                                onChange={(e) => {this.filters.active = e === null ? null : e.value; this.filterDevices()}}
                            />
                        </div>
                    </div>
                    <div style={{flex: 1, flexDirection: 'row', display: 'flex'}}>
                        <span style={{flex: 1, textAlign: 'right', verticalAlign: 'middle', margin: 'auto', paddingRight: 10}}>Device: </span>
                        <div style={{flex: 1, verticalAlign: 'middle'}}>
                            <Select
                                options={this.state.devices.filter(e => {
                                    return e.deviceInfo.type === DeviceType.MASTER;
                                }).map(e => {
                                    return {label: e.deviceInfo.name + " (" + e.deviceInfo.deviceid + ")", value: e.deviceInfo.deviceid};
                                })}
                                menuPosition='fixed'
                                onChange={(e) => {this.filters.devices = e === null ? null : e.map(y => {return y.value;}); this.filterDevices()}} 
                                isMulti={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="device-container">
                    {
                        // ADMIN VIEW
                        LCSS.account.level >= 255 &&
                        this.state.filteredDevices.map((e, i) => {
                           console.log(e,'eeee')
                            if(e.deviceType === DeviceType.MASTER)
                                return <DeviceBox key={"dev_box_" + e.deviceInfo.deviceid} device={e} />
                            else
                                return null;
                        })
                    }
                    {
                        // USER VIEW
                        LCSS.account.level < 255 &&
                        <Redirect to="/" />
                    }
                </div>
            </div>
        );
    }

}