import React from 'react';
import ReactTooltip from 'react-tooltip';
import { addZ } from '../Misc/Helpers';

/**
 * 
 * @param {{r: number, g: number, b: number}} a 
 * @param {{r: number, g: number, b: number}} b 
 * @param {number} t 
 */
const colorLerp = (a, b, t) => {
    return `rgb(${a.r + (b.r - a.r) * t},${a.g + (b.g - a.g) * t},${a.b + (b.b - a.b) * t})`;
}

export default class HeatMapTimeline extends React.Component {


    render () {

        /**
         * @type {{x: number, y: number, value: number}[]}
         */
        let nd = this.props.data;
        nd.sort((a, b) => {
            return a.x - b.x;
        })
        
        // Get dataset length 
        let xset = [];
        let yset = [];

        let max = 0;

        for(let v of nd.map(d => d.x)) {
            if(!xset.includes(v))
                xset.push(v);
        }
        for(let v of nd.map(d => d.y)) {
            if(!yset.includes(v))
                yset.push(v);
        }

        nd.forEach((e) => {
            if(e.value > max) {
                max = e.value;
            }
        })

        max = Math.max(max, 1);

        const leftPad = 150;
        const bottomPad = 60;
        
        const width = this.props.width | 300;
        let height = Math.min((this.props.width / xset.length), 40) * yset.length;

        const margin = this.props.margin | 1;

        const blockw = (width - leftPad) / xset.length - margin;
        const blockh = height / yset.length - margin;

        let lvix = -1;
        let lvv = -1;
        let lvixm = 0;

        return <div>
            <ReactTooltip id={'hmap-1'}/>
            <svg viewBox={`0 0 ${width} ${height}`} width={'100%'} height={height + bottomPad}>
                {
                    yset.map((v, i) => {
                        let val = v;
                        if(this.props.headers) {
                            val = this.props.headers[v];
                        }
                        return <text key={i} transform={`translate(3, ${(blockh + margin) * i + 16})`} fontSize={'0.9em'}>{val}</text>
                    })

                }
                {
                    nd.map((e, z) => {
                        let ix = lvix;
                        if(e.x !== lvv) {
                            ix = nd.findIndex(g => g.x === e.x) - lvixm;
                            lvv = e.x;
                            lvix = ix;
                        }
                        else {
                            lvixm++;
                        }
                        return <rect key={z} data-for={'hmap-1'} data-tip={e.value} x={leftPad + ix * (blockw + margin)} y={yset.findIndex(g => g === e.y) * (blockh + margin)} width={blockw} height={blockh}
                            style={{fill: colorLerp(this.props.colorMin, this.props.colorMax, e.value/max)}} />
                    })
                }
                {
                    xset.map((v, i) => {
                        if(i % 4 !== 0)
                            return null;
                        
                        let d = new Date(v);

                        return <text key={i} x={leftPad + i * (blockw + margin) - 10} y={height + 10}>
                        <tspan fontSize={"1em"}>
                        {
                            addZ(d.getHours()) + ":" + addZ(d.getMinutes())
                        }
                        </tspan>
                        {
                            i % 12 === 0 &&
                            <tspan fontSize={"0.8em"} dy={"1.1em"} dx={-33}>
                                {
                                    addZ(d.getDate()) + "." + addZ(d.getMonth() + 1)
                                }
                            </tspan>
                        }
                        </text>
                    })
                }
            </svg>
        </div>

    }

}