import { useEffect, useLayoutEffect } from "react"
import WSController, { ClientWSCommands } from "../Components/WSController"
import LCSS from "./LCSS"
import { useState } from "react"
import Device from "../Misc/Device"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom"

export default function Report() {
  const [orgs, setOrgs] = useState([])
  const [selectedOrg, setSelectedOrg] = useState(LCSS.account.level >= 127 ? 2 : LCSS.account.organizationId)
  const [reports, setReports] = useState()
  const [devices, setDevices] = useState(Device.devices)
  const [user, setUser] = useState(Device.devices)
  let history = useHistory()

  useEffect(() => {
    LCSS.fetchOrganizations((orgs) => {
      console.log(orgs)
      setOrgs(orgs)
    })
  }, [])

  useEffect(() => {
    //get reports for org

    WSController.request(
      {
        cmd: ClientWSCommands.GETREPORTS,
        organization: selectedOrg,
      },
      ({ data }) => {
        const groupedByYear = data.reduce((acc, obj) => {
          // Extract the year from the date string
          const year = new Date(obj.month).getFullYear()

          // If the year doesn't exist in the accumulator, create an array for it
          if (!acc[year]) {
            acc[year] = []
          }

          // Push the object into the corresponding year array
          acc[year].push(obj)

          return acc
        }, {})

        for (const year in groupedByYear) {
          groupedByYear[year].sort((a, b) => new Date(a.month) - new Date(b.month))
        }

        const arr = Object.entries(groupedByYear)?.map(([year, data]) => {
          return { year: parseInt(year), data }
        }).reverse()

        console.log(groupedByYear)
        setReports(arr)
      }
    )
  }, [selectedOrg])

  const format = (data) => {
    const date = new Date(data)

    const monthName = date.toLocaleString("en-US", { month: "short" })

    return monthName
  }

  return (
    <div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
      <span style={{ fontSize: "20px", fontWeight: "bold" }}>Reports</span>
      {reports ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", background: "#fff", padding: 20 }}>
          {reports.length === 0 ? (
            <span>No Reports</span>
          ) : (
            <>
            {LCSS.account.level >= 127 && <select style={{ width: "fit-content" }} value={selectedOrg} onChange={(e) => setSelectedOrg(e.target.value)}>
                {orgs.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            }

              {reports &&
                reports?.map((item, index) => (
                  <div style={{ display: "flex", flexDirection: "column", gap: "10px", fontWeight: 600 }} key={index}>
                    {item.year}
                    <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                      {item.data.map((item, index) => (
                        <button
                          onClick={() => history.push(`/reports/${item.id}`)}
                          key={index}
                          style={{ display: "flex", alignItems: "center", gap: "8px" }}
                        >
                          {format(item.month)}
                          {
                            <div
                              style={{ width: "10px", height: "10px", borderRadius: "99px", background: item.has_errors ? "#d54848" : "#48d56d" }}
                            />
                          }
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
  
            </>
          )}
        </div>
      ) : (
        <b>Loading...</b>
      )}
    </div>
  )
}
