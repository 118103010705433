import { Link, useParams, useHistory } from "react-router-dom/cjs/react-router-dom"
import WSController, { ClientWSCommands } from "../Components/WSController"
import { useEffect, useState, useRef } from "react"
import Device from "../Misc/Device"
import { addZ, formatDate, formatDateTime, formatTime, legendItem } from "../Misc/Helpers"
import LCSS from "./LCSS"
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts"
import { usePDF } from "react-to-pdf"

const getTime = (time) => {
  let timeString = "2024-01-15T14:30:00Z" // Use your actual time string

  // Create a Date object from the time string
  let date = new Date(time)

  // Specify the formatting options
  let options = { year: "numeric", month: "long" }

  // Format the date
  let formattedDate = date.toLocaleDateString("en-US", options)

  return formattedDate
}

const dataHeaders = [
  { vName: "Error", dName: "Error", tooltip: "", decimals: 0, unit: "" },
  { vName: "name", dName: "Name", tooltip: "", decimals: -1, unit: "" },
  { vName: "Uptime", dName: "Uptime", tooltip: "Uptime", decimals: 0, unit: "" },
  { vName: "UVCTime", dName: "UVC time", tooltip: "UVC time", decimals: 0, unit: "" },
  { vName: "UVCTimeP", dName: "UVC %", tooltip: "UVC %", decimals: 1, unit: "%" },
  { vName: "Boxcount", dName: "Total Trays", tooltip: "", decimals: 0, unit: "" },
  { vName: "ExtVolt", dName: "Ext Voltage", tooltip: "", decimals: 1, unit: "V" },
  { vName: "T1", dName: "Temp LCDIO", tooltip: "", decimals: 0, unit: "°C" },
  { vName: "T2", dName: "Temp Cabin", tooltip: "", decimals: 0, unit: "°C" },
]

const dataHeadersMobile = [
  { vName: "name", dName: "Name", tooltip: "", decimals: -1, unit: "" },
  { vName: "Error", dName: "Error", tooltip: "", decimals: 0, unit: "" },
  { vName: "Uptime", dName: "Uptime", tooltip: "Uptime", decimals: 0, unit: "" },
  { vName: "UVCTime", dName: "UVC Time", tooltip: "UVC time", decimals: 0, unit: "" },
  { vName: "Boxcount", dName: "Total Trays", tooltip: "", decimals: 0, unit: "" },
  { vName: "T1", dName: "Temp LCDIO", tooltip: "", decimals: 0, unit: "°C" },
  { vName: "T2", dName: "Temp Cabin", tooltip: "", decimals: 0, unit: "°C" },
]

let headers = dataHeaders
//
const mobilePortrait = window.innerWidth < 768

if (mobilePortrait) {
  headers = dataHeadersMobile
}

const FormatDataField = (value, field) => {
  if (value === undefined) {
    return ""
  }
  if (value === null) {
    return "-" + field.unit
  }

  if (field.vName === "Uptime") {
    return formatTime(value)
  }
  if (field.vName === "UVCTime") {
    return formatTime(value)
  }

  if (field.vName === "Boxcount") {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0")
  }

  if (field.decimals >= 0) {
    return value.toFixed(field.decimals) + field.unit
  }

  // Default string return
  return value.toString()
}


function createMonthlyArray(year, data) {
  const result = []

  for (let month = 0; month < 12; month++) {
    const date = new Date(year, month, 1)

    const monthString = String(month + 1).padStart(2, "0")
    // Create date string in YYYY-MM-DD format
    const dateString = `${year}-${monthString}-01`

    result.push({ month: dateString, tray_count: 0 })
  }

  return result
}

const years = [2024, 2025, 2026, 2027, 2028]

function getYearFromDate(inputDate) {
  // Create a new Date object from the input
  const date = new Date(inputDate)

  // Check if the date is valid
  if (isNaN(date)) {
    throw new Error("Invalid date")
  }

  // Extract and return the year
  return date.getFullYear()
}

export default function Report() {
  const [report, setReport] = useState()
  const [note, setNote] = useState(report?.note)
  const [devices, setDevices] = useState(Device.devices)

  const [deviceErrors, setDeviceErrors] = useState()
  const [stats, setStats] = useState()
  const [org, setOrg] = useState(null)
  const { id } = useParams()
  let history = useHistory()
  const { toPDF, targetRef } = usePDF({ filename: `report-${report?.month.slice(0, 7)}.pdf`, method: "save", page: { orientation: "landscape" } })
  
  const saveNote = (content) => {
    WSController.request(
      {
        cmd: ClientWSCommands.SAVEREPORTNOTE,
        id: id,
        content: content,
      },
      ({ data }) => {}
    )
  }

  useEffect(() => {
    WSController.request(
      {
        cmd: ClientWSCommands.GETREPORT,
        id: id,
      },
      ({ data }) => {
        setReport(data[0])
        setNote(data[0].note)
        setOrg(data[0].organization_id)
      }
    )
  }, [])

  useEffect(() => {
    if (report) {
      WSController.request(
        {
          cmd: ClientWSCommands.GETREPORTSTATS,
          org: report.organization_id,
          year: getYearFromDate(report.month),
        },
        ({ data }) => {
          setStats(data)
        }
      )

      WSController.request(
        {
          cmd: ClientWSCommands.GETDEVICEERROR,
          report_id: report.id,
          month: report.month,
        },
        (res) => {
          setDeviceErrors(res.data)
        }
      )
    }
  }, [report])

  const formatNumberWithSpaces = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0")



  return (
    <>
      <div id="pdf-content" ref={targetRef} style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
     
        {report && deviceErrors && stats ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontSize: "20px", fontWeight: "bold" }}>{getTime(report.month)}</span>{" "}
              <button onClick={() => toPDF()}>Download PDF</button>{" "}
            </div>

            <div style={{ background: "#fff", padding: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "20px" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                  <span style={{ fontWeight: "700", fontSize: 20 }}>Total trays in a year</span>
                  <b style={{ fontSize: 24 }}>{stats.total_tray_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b>
                </div>

                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                  <span style={{ fontWeight: "700", fontSize: 20 }}>Trays by month</span>

                  <div style={{ width: 400, height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        width={150}
                        height={40}
                        data={createMonthlyArray(getYearFromDate(report.month, stats.byMonth)).map((item, index) => {
                          const v = stats.byMonth.find((i) => i.month === item.month)

                          if (v) {
                            return { month: index + 1, tray_count: v.tray_count }
                          }

                          return { month: index + 1, tray_count: 0 }
                        })}
                      >
                        <XAxis dataKey="month" />
                        <YAxis tickFormatter={formatNumberWithSpaces} />
                        <Tooltip formatter={formatNumberWithSpaces} wrapperStyle={{ width: "auto", backgroundColor: "#fff" }} />
                        <Bar dataKey="tray_count" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                  <span style={{ fontWeight: "700", fontSize: 20 }}>Trays by year</span>

                  <div style={{ width: 400, height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        margin={{ top: 20, right: 0, left: 20, bottom: 0 }}
                        width={150}
                        height={40}
                        data={years.map((year, index) => {
                          const v = stats.byYear.find((i) => i.year === year)

                          if (v) {
                            return { year: year, tray_count: v.tray_count }
                          }

                          return { year: year, tray_count: 0 }
                        })}
                      >
                        <XAxis dataKey="year" />
                        <YAxis tickFormatter={formatNumberWithSpaces} />
                        <Tooltip formatter={formatNumberWithSpaces} wrapperStyle={{ width: "auto", backgroundColor: "#fff" }} />
                        <Bar dataKey="tray_count" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>

              <table className="data-table" style={{ width: "100%", textAlign: "left" }} id="pdf-content">
                <tbody>
                  <tr>
                    {headers.map((e, i) => {
                      return <th key={"dh-" + i}>{e.dName}</th>
                    })}
                  </tr>
             
                  {deviceErrors
                    .map((e, i) => ({ ...e, device: { ...(Array.isArray(devices) ? devices : [devices]).find((item) => item.deviceInfo.id === Number(e.device_id)) } }))
                    .map((e, i) => {
                      let cls = ""
                      return (
                        <tr key={"ddv-" + i}>
                          {headers.map((x, z) => {
                            if (x.vName === "Error") {
                              return (
                                <td className={cls} key={"dv-" + z}>
                                  <div
                                    style={{ width: "12px", height: "12px", borderRadius: "99px", background: e.has_errors ? "#d54848" : "#48d56d" }}
                                  />
                                </td>
                              )
                            } else if (x.vName === "Boxcount") {
                              return (
                                <td className={cls} key={"dv-" + z}>
                                  {FormatDataField(e.Boxcount, x)}
                                </td>
                              )
                            }

                            if (x.vName === "name") {
                              return (
                                <td className={cls} key={"dv-" + z}>
                                  {FormatDataField(e.name, x)}
                                </td>
                              )
                            } else if (x.vName === "UVCTimeP") {
                              if (e.installed !== null) {
                                const installTime = new Date(e.installed).getTime() / 1000
                                const timeSinceStart = new Date().getTime() / 1000 - installTime
                                let dtip = `Since ${formatDate(new Date(e.installed), "fi")}`
                                return (
                                  <td className={cls} key={"dv-" + z} data-tip={dtip}>
                                    {FormatDataField((e.UVCTime / timeSinceStart) * 100, x)}
                                  </td>
                                )
                              } else {
                                let dtip = `Not installed`
                                return (
                                  <td className={cls} key={"dv-" + z} data-tip={dtip}>
                                    -%
                                  </td>
                                )
                              }
                            } else {
                              return (
                                <td className={cls} key={"dv-" + z}>
                                  {FormatDataField({ ...e, ExtVolt: e.device.masterData.ExtVolt }[x.vName], x)}
                                </td>
                              )
                            }
                          })}
                          <td key={"dv-" + i}>
                            <button onClick={() => history.push(`${history.location.pathname}/${e.deviceid}`)}>{`>`}</button>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>

              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <b>Notes</b>

                <textarea
                  onChange={(e) => setNote(e.target.value)}
                  onBlur={(e) => saveNote(e.target.value)}
                  value={note}
                  rows={8}
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    borderRadius: 5,
                    padding: 10,
                    width: "auto",
                    fontSize: 16,
                  }}
                ></textarea>
              </div>
            </div>
          </>
        ) : (
          <b>Loading...</b>
        )}
      </div>
    </>
  )
}
